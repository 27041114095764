var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.region)?_c('div',{staticClass:"region-page"},[_c('div',{staticClass:"region-page__header"},[_c('h1',{staticClass:"region-page__header-title"},[_vm._v(" "+_vm._s(_vm.$t('geo_pages.region_page.title', { region_name: _vm.region.title }))+" ")])]),_c('p',{staticClass:"page-description",domProps:{"innerHTML":_vm._s(_vm.region.description)}}),_c('RegionsBlock',{attrs:{"id":"regions-block","selected":_vm.region.id,"is-on-region-page":true}}),_c('div',{staticClass:"buttons-block"},[_c('WMButton',{staticClass:"show-on-map",attrs:{"data-gtm":"show_city_venues_on_map_button","small":"","color":"primary","icon-class-name":"fa-solid fa-location-dot fa-xs"},on:{"click":_vm.toggleShowOnMap,"close":_vm.toggleShowOnMap}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('common.show_on_map')))])]),_c('router-link',{staticClass:"search-button__link",attrs:{"to":{
        path: _vm.useGetLocalizedPath('search'),
        query: {
          lat: _vm.region.latitude,
          lng: _vm.region.longitude,
          place: _vm.region.title
        }
      },"exact":""}},[_c('WMButton',{staticClass:"show-on-map",attrs:{"data-gtm":"region_page_search_button","small":"","color":"primary","icon-class-name":"fa-solid fa-magnifying-glass fa-xs"}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('common.search_with_filters')))])])],1)],1),_c('CitiesList',{attrs:{"cities":_vm.cities}}),(_vm.pagesCount > 1)?_c('PagePagination',{staticClass:"seo-pagination",attrs:{"pages":_vm.pagesCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),_c('TrustPublication'),(_vm.venues.length)?_c('ShowOnMapModal',{attrs:{"venues":_vm.venues},model:{value:(_vm.isMapModalVisible),callback:function ($$v) {_vm.isMapModalVisible=$$v},expression:"isMapModalVisible"}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }