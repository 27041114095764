import ModalPopup from '@/shared/components/ModalPopup.vue';
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import Vue from 'vue';
import MapInfoWindowShortened from '@/Search/components/MapInfoWindowShortened.vue';
const DEFAULT_MAP_ZOOM = 16;
const DEFAULT_MARKER_Z_INDEX = 888;
export default defineComponent({
    components: {
        ModalPopup,
        MapInfoWindowShortened
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        venues: {
            type: Array,
            default: () => []
        }
    },
    setup(props, { emit }) {
        const onClose = () => {
            emit('close');
        };
        const google = ref(null);
        const defaultMapMarkerAnchorPoint = ref(null);
        const defaultMapMarkerLabelPoint = ref(null);
        const mapRef = ref(null);
        const map = ref(null);
        const mapMarkers = ref([]);
        const mapOptions = reactive({
            zoomControl: true,
            zoomControlOptions: {
                position: null
            },
            scrollwheel: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
            disableDoubleClickZoom: false
        });
        const mapDefaultCenter = computed(() => {
            return {
                lat: props.venues[0].venue_geo.latitude,
                lng: props.venues[0].venue_geo.longitude
            };
        });
        const zoom = ref(DEFAULT_MAP_ZOOM);
        const serviceAnchor = ref(null);
        const selectedVenueId = ref(0);
        const infoWindowOpened = ref(false);
        const infoWindowPosition = ref(null);
        const selectedVenue = computed(() => {
            const index = props.venues.findIndex(venue => venue.venue_id === selectedVenueId.value);
            return props.venues[index];
        });
        const fitMapBounds = () => {
            if (!props.venues.length)
                return;
            /*
              Gets coordinates of all initialized markers and sets the map bounds the way all the markers fit the screen view.
              Map zoom automatically adjusts the bounds, map center is the center of all markers.
            */
            const coordinatesList = [];
            props.venues.forEach(venue => coordinatesList.push(new google.value.maps.LatLng(venue.venue_geo.latitude, venue.venue_geo.longitude)));
            //  Create a new viewpoint bound
            let bounds = new google.value.maps.LatLngBounds();
            //  Go through each...
            for (let i = 0, LtLgLen = coordinatesList.length; i < LtLgLen; i++) {
                //  And increase the bounds to take this point
                bounds.extend(coordinatesList[i]);
            }
            map.value?.fitBounds(bounds);
            // const center = map.value.getCenter();
        };
        watch(() => props.venues, () => {
            if (props.venues && props.venues.length) {
                mapMarkers.value = [];
                props.venues.forEach(venue => {
                    mapMarkers.value.push({
                        position: {
                            lat: venue.venue_geo.latitude,
                            lng: venue.venue_geo.longitude
                        },
                        venueId: venue.venue_id,
                        venueName: venue.venue_name,
                        mapMarkerLabelPoint: defaultMapMarkerLabelPoint.value,
                        mapMarkerAnchorPoint: defaultMapMarkerAnchorPoint.value,
                        zIndex: DEFAULT_MARKER_Z_INDEX
                    });
                });
            }
        }, { immediate: true });
        watch(() => props.value, () => {
            if (props.value) {
                // Map init
                // https://diegoazh.github.io/gmap-vue/#the-three-main-utilities
                Vue.$gmapApiPromiseLazy().then(mapsAPI => {
                    google.value = mapsAPI;
                    defaultMapMarkerAnchorPoint.value = new google.value.maps.Point(40, 35);
                    defaultMapMarkerLabelPoint.value = new google.value.maps.Point(24, 26);
                    serviceAnchor.value = new google.value.maps.Point(7, 26);
                    mapOptions.zoomControlOptions.position =
                        google.value.maps.ControlPosition.TOP_LEFT;
                });
                mapRef.value.$mapPromise.then(() => {
                    map.value = mapRef.value.$mapObject;
                    if (props.venues?.length > 1) {
                        // When more than 1 venue marker - fit map bounds automatically
                        fitMapBounds();
                    }
                });
            }
        }, { immediate: true });
        const onMarkerClick = (marker) => {
            selectedVenueId.value = marker.venueId;
            infoWindowOpened.value = true;
            infoWindowPosition.value = marker.position;
        };
        return {
            infoWindowOpened,
            infoWindowPosition,
            mapDefaultCenter,
            mapMarkers,
            mapOptions,
            mapRef,
            selectedVenue,
            zoom,
            onClose,
            onMarkerClick
        };
    }
});
