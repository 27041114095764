import { defineComponent, computed, watch, reactive, ref, onUnmounted } from '@vue/composition-api';
import RegionsBlock from '@/GeoPages/components/RegionsBlock.vue';
import CitiesList from '@/GeoPages/components/CitiesList.vue';
import PagePagination from '@/components/PagePagination.vue';
import TrustPublication from '@/components/TrustPublication.vue';
import { useGetRegionQuery, useGetRegionCitiesQuery } from '@/generated-types/graphql.types';
import ShowOnMapModal from '@/shared/components/ShowOnMapModal.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { SITE_URL } from '@/App.vue';
import { useMeta } from '@/shared/composables/useMeta';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
import useStructuredData from '@/shared/composables/useStructuredData';
import EventBus from '@/shared/services/eventBus';
const PAGE_SIZE = Number(process.env.VUE_APP_SEO_PAGINATION);
export default defineComponent({
    components: {
        RegionsBlock,
        CitiesList,
        PagePagination,
        TrustPublication,
        ShowOnMapModal
    },
    setup(_, context) {
        const { root } = context;
        const region = ref(undefined);
        const cities = ref([]);
        const pagesCount = ref(0);
        const isMapModalVisible = ref(false);
        const getRegionVariables = reactive({
            slug: '',
            pagination: {
                page: 1,
                size: PAGE_SIZE
            }
        });
        const getRegionOptions = reactive({
            enabled: false,
            fetchPolicy: 'no-cache'
        });
        const { refetch, onResult } = useGetRegionQuery(getRegionVariables, getRegionOptions);
        onResult((result) => {
            if (!result?.data?.get_specific_location)
                return;
            region.value = {
                id: result.data.get_specific_location.seo_entity_id,
                title: result.data.get_specific_location.seo_entity_name,
                description: result.data.get_specific_location.seo_entity_description,
                latitude: result.data.get_specific_location.latitude,
                longitude: result.data.get_specific_location.longitude,
                image: getRandomImage(result.data.get_specific_location),
                slug: result.data.get_specific_location.seo_entity_slug
            };
            getRegionCitiesVariables.slug = region.value.slug;
            getRegionCitiesOptions.enabled = true;
            refetchRegionCities(getRegionCitiesVariables);
            useMeta({
                title: root.$i18n
                    .t('meta.region.title_tag', {
                    region_name: region.value.title
                })
                    .toString(),
                description: root.$i18n
                    .t('meta.region.description_tag', {
                    region_name: region.value.title
                })
                    .toString(),
                keywords: root.$i18n
                    .t('meta.general.meta_keywords', {
                    returnObjects: true
                })
                    .map((keyword) => `${keyword} ${region.value.title}`)
                    .join(','),
                url: SITE_URL +
                    useGetLocalizedPath(`${GeoPagesRoutes.CITIES}${region.value.slug}`),
                imageUrl: region.value.image || '',
                noIndex: page.value > 1
            });
        });
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        EventBus.$on('onBreadcrumbsReady', data => {
            injectStructuredData({
                '@context': 'https://schema.org',
                ...data
            });
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
        const getRegionCitiesVariables = reactive({
            slug: '',
            pagination: {
                page: 1,
                size: PAGE_SIZE
            }
        });
        const getRegionCitiesOptions = reactive({
            enabled: false,
            fetchPolicy: 'no-cache'
        });
        const { refetch: refetchRegionCities, onResult: onRegionCitiesResult } = useGetRegionCitiesQuery(getRegionCitiesVariables, getRegionCitiesOptions);
        onRegionCitiesResult((result) => {
            if (!result?.data?.get_specific_location)
                return;
            cities.value = result.data.get_specific_location.cities?.result || [];
            pagesCount.value =
                result.data.get_specific_location.cities?.total_pages || 0;
        });
        /**
         * Get random image from the region locations
         */
        const getRandomImage = (region) => {
            const regionCities = region?.cities?.result;
            if (!regionCities)
                return '';
            // get random city from the regionCities array
            const randomCity = regionCities[Math.floor(Math.random() * regionCities.length)];
            const cityVenues = randomCity?.venues?.result || [];
            const randomVenue = cityVenues[Math.floor(Math.random() * cityVenues.length)];
            const venueMainImage = randomVenue?.venue_images.find(image => image && image.order === 0);
            return venueMainImage
                ? `${randomVenue?.venue_images.find(image => image && image.order === 0)
                    ?.image_url}/tr:w-300,h-230`
                : '/img/common/placeholder-image.png';
        };
        // Re-fetch region cities if the slug is changed
        watch(() => root.$route.params, (newParams, oldParams) => {
            if (newParams && newParams !== oldParams) {
                getRegionVariables.slug = `/${newParams.region}`;
                getRegionOptions.enabled = true;
                refetch(getRegionVariables);
            }
        }, { immediate: true });
        const page = computed({
            get() {
                return +root.$route.query.page || 1;
            },
            set(page) {
                if (page !== +root.$route.query.page && page > 1) {
                    globalThis.$router.replace({ query: { page } });
                }
            }
        });
        // Setting first page of cities without query params
        if (!page.value)
            page.value = 1;
        // Re-fetch new list of cities for the current page
        watch(page, (curr, prev) => {
            if (prev && curr !== prev) {
                if (!page.value)
                    page.value = 1;
                getRegionVariables.pagination.page = page.value;
                getRegionOptions.enabled = true;
                refetch(getRegionVariables);
            }
        }, { immediate: true });
        // Re-fetch region object if the language is changed
        watch(() => globalThis.$i18n.locale, (curr, prev) => {
            if (!prev)
                return;
            getRegionOptions.enabled = true;
            refetch(getRegionVariables);
        }, { immediate: true });
        const toggleShowOnMap = () => {
            isMapModalVisible.value = !isMapModalVisible.value;
        };
        const venues = computed(() => {
            let _venues = [];
            cities.value?.forEach(city => {
                if (city?.venues?.result) {
                    _venues.push(city.venues.result);
                }
            });
            return [].concat.apply([], _venues);
        });
        return {
            cities,
            isMapModalVisible,
            page,
            pagesCount,
            region,
            venues,
            toggleShowOnMap,
            useGetLocalizedPath
        };
    }
});
