import { defineComponent } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { VenueRoutes } from '@/Venue/venue.const';
export default defineComponent({
    props: {
        venue: {
            type: Object,
            default: () => { }
        }
    },
    setup() {
        return { VenueRoutes, useGetLocalizedPath };
    }
});
